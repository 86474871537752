.youtube-video-wrapper {
  position: relative;
}
/* .youtube-video-wrapper .video-player {
  object-fit: fill;
  width: 100%;
  height: 100%;
  margin: auto;
} */
.youtube-video-wrapper .youtube-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.youtube-video-wrapper > div {
  width: auto;
  height: auto;
}
.youtube-video-wrapper .video-player {
  padding-top: 56.25%;
  position: relative;
  margin: auto;
}

.youtube-video-wrapper .video-player > div {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
