/* Common */
.hide {
  display: none;
}
.opa-0 {
  opacity: 0;
}
.opa-1 {
  opacity: 0.1;
}
.opa-2 {
  opacity: 0.2;
}
.opa-3 {
  opacity: 0.3;
}
.opa-4 {
  opacity: 0.4;
}
.opa-5 {
  opacity: 0.5;
}
.opa-6 {
  opacity: 0.6;
}
.opa-7 {
  opacity: 0.7;
}
.opa-8 {
  opacity: 0.8;
}
.opa-9 {
  opacity: 0.9;
}
.opa-10 {
  opacity: 1;
}

/* General */
.form-control-lg {
  font-size: 16px;
}
.question {
  text-transform: lowercase;
}
.question::first-letter {
  text-transform: capitalize;
}
.empty-view-wrapper {
  margin: 15px;
  margin-top: 40px;
}
.empty-view-wrapper .empty-view {
  border-color: #756e6b;
  border-style: dashed;
  border-radius: 15px;
  padding: 15px;
  text-align: center;
}
.empty-view-wrapper .empty-view div {
  max-width: 800px;
  margin: auto;
}
.tab-wrapper .mdc-tab {
  outline: none;
}
.tab-wrapper .mdc-tab span {
  font-size: 13px;
}
.tab-wrapper .mdc-tab.mdc-tab--active span {
  color: black;
}
.tab-wrapper .mdc-tab-indicator {
  background-color: white;
}
.tab-wrapper .mdc-tab-indicator span {
  background-color: black;
}

.back-cover {
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100%;
  top: 0;
  left: 0;
  height: 300%;
}
.search-bar .predicted-questions {
  width: 100%;
  outline: none;
  font-size: 20px;
  color: #000;
  font-family: Muli;
  position: absolute;
  margin-right: 15px;
  z-index: 100;
}
.search-bar .predicted-questions > div {
  padding: 10px;
  background-color: #fff;
  margin-right: 30px;
  border: 2px solid #000;
  max-height: 326px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.search-bar .predicted-questions > div > div {
  margin: 5px;
  cursor: pointer;
}

.overflow-unset {
  overflow: unset !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-primary {
  background-color: #088ed4;
}

/* ****************** Toggle Switch ****************** */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25.5px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 19.5px;
  width: 19.5px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 0.75px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(19.5px);
  -ms-transform: translateX(19.5px);
  transform: translateX(19.5px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 25.5px;
}
.slider.round:before {
  border-radius: 50%;
}

.small-footer .footer-bottom > div {
  border-top: none;
}
.HubSpoke {
  padding-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.HubSpoke h3 {
  color: rgba(0, 0, 0, 0.9);
}
.HubSpoke h5 {
  padding-left: 10px;
  font-weight: 500;
}
.HubSpoke .question-warraper h3 {
  cursor: pointer;
  font-weight: 500;
  color: #088ed4;
}
.HubSpoke .title {
  color: #0b72a8;
  padding: 15px 0px;
}
.HubSpoke .title h1 {
  font-weight: 900;
}
.SwipeAnswer small {
  display: block;
}
.SwipeAnswer .contact-btn {
  max-width: 150px;
}
.btn-change {
  position: absolute;
  top: 0;
  right: 0;
}
.hide {
  display: none;
}
.LawyerContactModal .form-group .form-control.input {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  color: black;
  border-color: black;
}
.LawyerContactModal .form-group .form-control.textarea {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  color: black;
  border-color: black;
}
.color-p {
  color: #088ed4 !important;
}
.clickable {
  cursor: pointer !important;
}
.clickable:hover {
  opacity: 0.9 !important;
}
.form-group.multiselect .rw-widget-container div {
  padding: 0px;
}
.form-group.multiselect ul li {
  margin-top: 3px !important;
  margin-bottom: 3px;
}
.form-group.multiselect .rw-select {
  width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.form-group.multiselect .rw-widget-input ul + input {
  width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
}
.profile-circle-32 {
  width: 32px;
  height: 32px;
  overflow: hidden;
  margin-right: 16px;
  border-radius: 100%;
}
.profile-circle-32 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.display-flex {
  display: flex;
}
.max-w-360 {
  max-width: 360px;
}
.max-w-490 {
  max-width: 490px;
}
button[disabled] {
  border-color: #878787 !important;
  background-color: #878787 !important;
}

/* Admin -> InviteAttorney */
.InviteAttorney {
  min-height: calc(100vh - 157.5px);
  padding-top: 30px;
  padding-bottom: 30px;
}

.form-control-lg {
  border-width: 1px;
}
.w-150px {
  width: 150px;
}

.or-youtube-link {
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
}
.or-youtube-link .btn {
  position: absolute;
  background: #3f51b5;
  color: white;
  right: 0;
  height: 26px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
}
.or-youtube-link input {
  padding-right: 65px;
}
@media screen and (max-width: 575px) {
  .HubSpoke h5 {
    padding-left: 0px;
  }
}
